<!-- 会员首页 -->
<template>
  <div class="ucenter">
    <el-row>
      <el-col :span="4" class="sider">
        <div id="title">会员首页</div>
        <el-collapse v-model="activeName" accordion>
          <el-collapse-item name="1">
            <template slot="title">
              <div class="collapse-item__title">交易中心</div>
            </template>
            <ul class="collapse-item__ul">
              <li
                v-for="(item, k) in tradeMenu"
                :key="k"
                @click="changeUrl(item)"
              >
                <span v-if="item.id === activeId" style="color: #ff414d">
                  {{ item.title }}
                </span>
                <span v-else>
                  {{ item.title }}
                </span>
              </li>
            </ul>
          </el-collapse-item>
        </el-collapse>
      </el-col>
      <el-col
        :span="20"
        style="padding: 0 15px; background: rgb(241, 241, 241)"
        v-loading="loading"
        element-loading-text="加载中"
      >
        <el-row style="background: #fff">
          <!-- vip用户信息 -->
          <el-col :span="7">
            <div id="top-left">
              <div id="vip">
                <div style="display: flex; flex-direction: row">
                  <div>
                    <img :src="require('@/assets/imgs/ucenter/vip.png')" />
                  </div>
                  <div style="position: relative; top: 72px; right: 30px">
                    0
                  </div>
                </div>
                <div id="username">您好，{{ user ? user.username : "" }}</div>
              </div>
              <div id="goods">
                <div style="width: 50%">当前拿货{{ order_num }}单</div>
                <div>保级0单 /升级0单</div>
              </div>
              <div id="progress">
                <el-progress
                  :text-inside="true"
                  :stroke-width="8"
                  :percentage="0"
                ></el-progress>
              </div>
              <div id="level-up">
                <div>再拿货0单，您下个结算周期升级为VIP1</div>
              </div>
            </div>
          </el-col>
          <!-- 统计、提现和充值 -->
          <el-col :span="10">
            <div id="top-middle">
              <div id="count">
                <div class="order">
                  <p>等待付款</p>
                  <p class="red">{{ wait_pay_num }}</p>
                </div>
                <div class="order">
                  <p>等待发货</p>
                  <p class="red">{{ wait_express_num }}</p>
                </div>
                <div class="order">
                  <p>退换货</p>
                  <p class="red">{{ refund_num }}</p>
                </div>
              </div>
              <div id="account">
                <div class="gold">
                  <p>金币</p>
                  <p class="red">￥{{ gold_total }}</p>
                </div>
                <div class="gold">
                  <p>优惠券</p>
                  <p class="red">0</p>
                </div>
              </div>
              <div id="charge">
                <div class="minu-btn">
                  <el-button size="mini" type="danger" @click="charge"
                    >金币充值</el-button
                  >
                </div>
                <div class="minu-btn">
                  <el-button size="mini" type="primary" @click="payBack"
                    >金币提现</el-button
                  >
                </div>
                <div class="minu-btn">
                  <router-link :to="'/gold'" target="_blank">
                    <el-button size="mini" type="info">金币明细</el-button>
                  </router-link>
                </div>
              </div>
            </div>
          </el-col>
          <!-- 客服二维码 -->
          <el-col :span="7">
            <div id="top-right">
              <el-image :src="require('@/assets/imgs/wxkf.png')"></el-image>
            </div>
          </el-col>
        </el-row>
        <!-- 退货信息 -->
        <el-row style="background: #fff">
          <el-col :span="12"
            >地址：<span style="color: #ff6700"
              >江苏省扬州市邗江区朱塘路110号普创电子二楼右门</span
            ></el-col
          >
          <el-col :span="5"
            >联系人：<span style="color: #ff6700">退货组</span></el-col
          >
          <el-col :span="5"
            >联系电话：<span style="color: #ff6700">15861388608</span></el-col
          >
          <el-col :span="2">复制</el-col>
        </el-row>
      </el-col>
    </el-row>
    <!-- 充值弹窗 -->
    <el-dialog
      title="金币充值"
      width="600px"
      :visible.sync="chargeDialogVisible"
    >
      <el-form :model="form">
        <el-form-item label="充值金额" label-width="120">
          <el-input-number
            v-model.trim="form.money"
            placeholder="请输入充值金额"
            :min="0.01"
          ></el-input-number>
        </el-form-item>
        <el-form-item label-width="120">
          <el-alert
            :closable="false"
            title="提示"
            type="error"
            description=" 1.充值(支付)金额仅用于产品零差价代拿货(发货)，产品知识产权纠纷自行负责，或联系产品供货商家辨别，与毛绒之家平台无关。
              2.为防止恶意下单，已充值或下单产生的退货退款将以同等值金币返还到用户账户，金币在本平台可用于支付产品、代发、快递费用。如需退现金应支付1%的手续费（此手续费在支付宝支付成功后已形成）。"
          />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="chargeDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="doCharge">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getUcenterCollapseItems } from "@/util/common";

export default {
  name: "Index",
  data() {
    return {
      activeName: "1",
      activeId: 0,
      tradeMenu: [],
      chargeDialogVisible: false,
      loading: false,
      user: null,
      gold_total: 0,
      order_num: 0,
      wait_pay_num: 0,
      wait_express_num: 0,
      refund_num: 0,
      form: {
        money: "",
      },
    };
  },
  created() {
    this.tradeMenu = getUcenterCollapseItems();
    this.user = this.$store.getters.getUser;
    this.getData();
  },
  mounted() {},
  methods: {
    /**
     * 获取用户统计数据
     */
    async getData() {
      const { data } = await this.$axios.get(
        this.$apiBaseUrl + "/web/user/total?uid=" + this.user.id
      );
      if (data.code === 1) {
        this.gold_total = data.data.gold;
        this.order_num = data.data.order_num;
        this.wait_pay_num = data.data.wait_pay_num;
        this.wait_express_num = data.data.wait_express_num;
        this.refund_num = data.data.refund_num;
      }
    },
    /**
     * 充值
     */
    charge() {
      this.chargeDialogVisible = true;
    },
    /**
     * 提现
     */
    payBack() {
      this.notifySucceed("提现功能开发中，请先联系客服提现!");
    },
    /**
     * 充值金币发起支付
     */
    doCharge() {
      if (this.user.id <= 0) {
        this.notifyError("请重新登录!");
        return false;
      }
      if (parseFloat(this.form.money) <= 0) {
        this.notifyError("请确认充值金额大于0!");
        return false;
      }
      window.location.href = this.$apiBaseUrl + 
        "/web/gold/charge?uid=" +
        this.user.id +
        "&money=" +
        this.form.money;
    },
    /**
     * 切换链接
     * @param {*} item
     */
    changeUrl(item) {
      if (item.url) {
        this.activeId = item.id;
        this.$router.push(item.url);
      }
    },
  },
};
</script>

<style scoped>
.ucenter {
  width: 1225px;
  font-size: 14px;
  margin: 0 auto;
}

.ucenter #title {
  height: 40px;
  line-height: 40px;
  font-size: 16px;
  font-weight: bold;
  color: #ff414d;
}
/* 左侧下拉菜单 */
.collapse-item__title {
  height: 40px;
  line-height: 40px;
  font-size: 16px;
  font-weight: bold;
}
.collapse-item__ul {
  padding: 5px 0 0 0;
}
.collapse-item__ul li {
  color: #757578;
  height: 30px;
  line-height: 30px;
  font-size: 14px;
  padding-left: 1em;
  cursor: pointer;
}

/* 右侧 */
/* 顶部 */
#top-left {
  background: url("../../assets/imgs/ucenter/user-bg.png");
  padding: 0 15px;
  padding-top: 20px;
  padding-bottom: 10px;
}
/* 用户 */
#top-left #vip {
  height: 100px;
  display: flex;
  flex-direction: row;
}
#vip img {
  width: 79px;
  height: 95px;
}
#vip #username {
  line-height: 75px;
  padding-left: 15px;
  font-size: 16px;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #333333;
  font-weight: bold;
}
/* 货品 */
#top-left #goods {
  margin-top: 5px;
  color: #a4521b;
  font-size: 14px;
  display: flex;
  flex-direction: row;
}
#top-left #level-up {
  margin-top: 5px;
  color: #a4521b;
  font-size: 14px;
  text-align: center;
  text-align: justify;
}
/* 统计、提现 */
#top-middle {
  padding: 0 20px;
  padding-top: 20px;
}
#top-middle #count,
#top-middle #account,
#top-middle #charge {
  display: flex;
  flex-direction: row;
}
#count .order {
  width: 33%;
  font-weight: bold;
  color: #666;
  text-align: center;
}
#top-middle #account {
  margin-top: 6px;
}
#account .gold {
  width: 50%;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  color: #666;
}
#top-middle #charge {
  margin-top: 15px;
}
#charge .minu-btn {
  width: 33%;
  font-weight: bold;
  text-align: center;
}
.minu-btn .el-button--mini {
  font-weight: bold;
}
#top-right {
  border-left: 1px solid #eee;
  width: 100%;
  height: 175px;
  padding: 10px 0;
  text-align: center;
}
#top-right .el-image {
  height: 100%;
}
#count .order .red,
#account .gold .red {
  margin-top: 6px;
  color: #ff414d;
}
</style>
